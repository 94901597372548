.text--muted {
  color: var(--muted-fg--color);
}

.text--primary {
  color: var(--primary-fg--color);
}

.text--light {
  color: var(--light-fg--color);
}

.bg--muted {
  background: var(--muted-bg--color);
}

.bg--dark {
  background: var(--dark-bg--color);
}

.bg--light {
  background: var(--light-bg--color);
}
