.mr-0 {
  margin-right: 0em !important;
}

.mr-1 {
  margin-right: .5em !important;
}

.mr-2 {
  margin-right: 1em !important;
}

.mr-3 {
  margin-right: 2em !important;
}

.mr-4 {
  margin-right: 4em !important;
}

.mr-5 {
  margin-right: 8em !important;
}

.ml-0 {
  margin-left: 0em !important;
}

.ml-1 {
  margin-left: .5em !important;
}

.ml-2 {
  margin-left: 1em !important;
}

.ml-3 {
  margin-left: 2em !important;
}

.ml-4 {
  margin-left: 4em !important;
}

.ml-5 {
  margin-left: 8em !important;
}

.mt-0 {
  margin-top: .0em !important;
}

.mt-1 {
  margin-top: .5em !important;
}

.mt-2 {
  margin-top: 1em !important;
}

.mt-3 {
  margin-top: 2em !important;
}

.mt-4 {
  margin-top: 4em !important;
}

.mt-5 {
  margin-top: 8em !important;
}

.mb-0 {
  margin-bottom: 0em !important;
}

.mb-1 {
  margin-bottom: .5em !important;
}

.mb-2 {
  margin-bottom: 1em !important;
}

.mb-3 {
  margin-bottom: 2em !important;
}

.mb-4 {
  margin-bottom: 4em !important;
}

.mb-5 {
  margin-bottom: 8em !important;
}

.mx-0 {
  margin-left: 0em !important;
  margin-right: 0em !important;
}

.mx-1 {
  margin-left: .5em !important;
  margin-right: .5em !important;
}

.mx-2 {
  margin-left: 1em !important;
  margin-right: 1em !important;
}

.mx-3 {
  margin-left: 2em !important;
  margin-right: 2em !important;
}

.mx-4 {
  margin-left: 4em !important;
  margin-right: 4em !important;
}

.mx-5 {
  margin-left: 8em !important;
  margin-right: 8em !important;
}

.my-0 {
  margin-top: 0em !important;
  margin-bottom: 0em !important;
}

.my-1 {
  margin-top: .5em !important;
  margin-bottom: .5em !important;
}

.my-2 {
  margin-top: 1em !important;
  margin-bottom: 1em !important;
}

.my-3 {
  margin-top: 2em !important;
  margin-bottom: 2em !important;
}

.my-4 {
  margin-top: 4em !important;
  margin-bottom: 4em !important;
}

.my-5 {
  margin-top: 8em !important;
  margin-bottom: 8em !important;
}

.m-0 {
  margin: 0em !important;
}

.m-1 {
  margin: .5em !important;
}

.m-2 {
  margin: 1em !important;
}

.m-3 {
  margin: 2em !important;
}

.m-4 {
  margin: 4em !important;
}

.m-5 {
  margin: 8em !important;
}
