.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-center-horizontal {
  display: flex;
  justify-content: center;
}

.flex-center-vertical {
  display: flex;
  align-items: center;
}

.flex-column {
  flex-direction: column;
}
