/* You can add global styles to this file, and also import other style files */

html, body {
  height: auto;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background: var(--gray-bg--color);
  box-sizing: border-box;
}

:root {
  --gray-bg--color: #F3F3F3;
  --dark-bg--color: black;
  --light-bg--color: #FFFFFF;
  --light-fg--color: #FFFFFF;
  --primary-fg--color: #D23B1F;
  --error-fg--color: #D23B1F;
  --warning-fg--color: #FA8800;
  --success-fg--color: #279915;
  --muted-fg--color: rgb(149, 149, 149);
  --muted-bg--color: rgb(251, 251, 251);
  --muted-shadow--color: rgb(231, 231, 231);
}

.b {
  font-weight: bold;
}

.w-100 {
  width: 100% !important;
}

.container {
  display: flex;
  justify-content: center;
  padding: 0 8px;
  @media (min-width: 1280px) {
    padding: 0 16.6%;
  }
}


app-thank-you {
  width: 100%;
  display: flex;
  justify-content: center;
}

@import "sass-helpers/colors";
@import "sass-helpers/flex";
@import "sass-helpers/margin";
@import "sass-helpers/padding";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
