.pr-1 {
  padding-right: .5em !important;
}

.pr-2 {
  padding-right: 1em !important;
}

.pr-3 {
  padding-right: 2em !important;
}

.pr-4 {
  padding-right: 4em !important;
}

.pr-5 {
  padding-right: 8em !important;
}

.pl-1 {
  padding-left: .5em !important;
}

.pl-2 {
  padding-left: 1em !important;
}

.pl-3 {
  padding-left: 2em !important;
}

.pl-4 {
  padding-left: 4em !important;
}

.pl-5 {
  padding-left: 8em !important;
}

.pt-1 {
  padding-top: .5em !important;
}

.pt-2 {
  padding-top: 1em !important;
}

.pt-3 {
  padding-top: 2em !important;
}

.pt-4 {
  padding-top: 4em !important;
}

.pt-5 {
  padding-top: 8em !important;
}

.pb-1 {
  padding-bottom: .5em !important;
}

.pb-2 {
  padding-bottom: 1em !important;
}

.pb-3 {
  padding-bottom: 2em !important;
}

.pb-4 {
  padding-bottom: 4em !important;
}

.pb-5 {
  padding-bottom: 8em !important;
}

.px-1 {
  padding-left: .5em !important;
  padding-right: .5em !important;
}

.px-2 {
  padding-left: 1em !important;
  padding-right: 1em !important;
}

.px-3 {
  padding-left: 2em !important;
  padding-right: 2em !important;
}

.px-4 {
  padding-left: 4em !important;
  padding-right: 4em !important;
}

.px-5 {
  padding-left: 8em !important;
  padding-right: 8em !important;
}

.py-1 {
  padding-top: .5em !important;
  padding-bottom: .5em !important;
}

.py-2 {
  padding-top: 1em !important;
  padding-bottom: 1em !important;
}

.py-3 {
  padding-top: 2em !important;
  padding-bottom: 2em !important;
}

.py-4 {
  padding-top: 4em !important;
  padding-bottom: 4em !important;
}

.py-5 {
  padding-top: 8em !important;
  padding-bottom: 8em !important;
}

.p-1 {
  padding: .5em !important;
}

.p-2 {
  padding: 1em !important;
}

.p-3 {
  padding: 2em !important;
}

.p-4 {
  padding: 4em !important;
}

.p-5 {
  padding: 8em !important;
}
